<template>
  <div class="content-layout">
    <div class="loaded-device-operations">
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:loaded_device_type:create'])"
          type="primary"
          class="add-btn"
          @click="handleCreator()"
          >新增</a-button
        >
      </div>
      <div class="data-filter">
        <a-select
          style="margin-right: 1em"
          allow-clear
          show-search
          optionFilterProp="label"
          placeholder="负载类型类别"
          v-model="category"
          class="search-keyword"
        >
          <a-select-option
            v-for="categoryOption in $constRepository.loadedDevice.CATEGORY_OPTION"
            :key="categoryOption.value"
            :value="categoryOption.value"
            :label="categoryOption.label"
          >
            {{ categoryOption.label }}
          </a-select-option>
        </a-select>
        <a-select v-if="filterSelected" v-model="filterSelected" style="width: 150px">
          <a-icon slot="suffixIcon" type="smile" />
          <a-select-option v-for="filter in filterOptions" :key="filter.value" :value="filter.value">
            {{ filter.label }}
          </a-select-option>
        </a-select>
        <a-input v-model="searchKeyword" class="search-keyword" clearable @keyup.enter.native="doSearch"></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.loadedDevice.columns"
      :data-source="tableDate"
      class="loaded-device-table"
      rowKey="loadedDeviceTypeId"
    >
      <template slot="createdTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="lastModificationTime" slot-scope="scope">{{ scope | parseTime | orNA }}</template>
      <template slot="regions" slot-scope="scope">
        <span v-if="scope">
          {{ scope.map((region) => region.name).join('、') }}
        </span>
        <span v-else>-</span>
      </template>
      <span slot="action" slot-scope="scope">
        <a-button
          v-if="$hasPermissions(['rcs:loaded_device_type:update'])"
          class="green-btn"
          ghost
          @click="handleEdit(scope)"
          >编辑</a-button
        >
        <a-divider type="vertical" />
        <a-button
          v-if="$hasPermissions(['rcs:loaded_device_type:get'])"
          class="green-btn"
          ghost
          @click="handleDetail(scope)"
          >详情</a-button
        >
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:loaded_device_type:delete'])" class="red-btn" ghost>
          <a-popconfirm okText="是" cancelText="否" title="是否删除负载类型?" @confirm="() => onDelete(scope)">
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </a-button>
      </span>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadedDeviceManagement',
  components: {},
  activated() {
    this.loadedPageData();
  },
  data() {
    return {
      category: undefined,
      filterSelected: this.$constRepository.loadedDevice.FILTER_OPTION_LOADED_DEVICE_NAME.value,
      filterOptions: this.$constRepository.loadedDevice.FILTER_OPTIONS,
      searchKeyword: '',
      requestParams: {},
      tableDate: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    processSearchKeyword() {
      this.$set(this.requestParams, 'name', undefined);
      this.$set(this.requestParams, 'loadedDeviceTypeId', undefined);
      this.$set(this.requestParams, 'category', undefined);
      if (this.category) {
        this.$set(this.requestParams, 'category', this.category);
      }
      switch (this.filterSelected) {
        case this.$constRepository.loadedDevice.FILTER_OPTION_LOADED_DEVICE_NAME.value:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        case this.$constRepository.loadedDevice.FILTER_OPTION_LOADED_DEVICE_ID.value:
          this.$set(this.requestParams, 'loadedDeviceTypeId', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listLoadedDevices();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listLoadedDevices();
      }
    },
    async listLoadedDevices() {
      const params = {
        loadedDeviceTypeId: this.requestParams.loadedDeviceTypeId,
        name: this.requestParams.name,
        category: this.requestParams.category,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.loadedDeviceType.getLoadedDeviceTypes(params);
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    handleCreator() {
      this.$router.push({ name: 'loadedDeviceCreator' });
    },
    handleEdit(record) {
      this.$router.push({ name: 'loadedDeviceEditor', params: { id: record.loadedDeviceTypeId } });
    },
    handleDetail(record) {
      this.$router.push({ name: 'loadedDeviceDetail', params: { id: record.loadedDeviceTypeId } });
    },
    async onDelete(record) {
      this.$apiManager.loadedDeviceType.deleteLoadedDeviceType(record.loadedDeviceTypeId).then(() => {
        this.$message.success('删除成功');
        this.loadedPageData();
      });
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
.loaded-device-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .loaded-device-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
